html {
    -webkit-locale: "en";
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    color: #0a0a0b;
    font-size: 16px;
    font-family: Basis Grotesque Pro,HelveticaNeue,sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --spacing-body: 40px 48px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    padding: 0;
    border-width: 0;
    background: none;
    color: #1e2ff1;
    text-decoration: none;
    font-size: inherit;
    outline: none;
}

svg:not(:root) {
    overflow: hidden;
}

audio, canvas, iframe, img, svg, video {
    vertical-align: middle;
}

*, :after, :before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

[type=checkbox], [type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}